import {NgModule} from '@angular/core'
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MessagingLibraryModule } from 'angular-messaging-library';
import {AwesomeLibModule} from 'awesome-lib';

@NgModule({
    imports: [CommonModule, PaginationModule, FormsModule, MessagingLibraryModule, AwesomeLibModule], 
    exports: [CommonModule, PaginationModule, FormsModule, MessagingLibraryModule, AwesomeLibModule]
})

export class AppLibraryModule{}