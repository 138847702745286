
<div class="row">
    <div class="col-sm-12"><div class="pageheader">HeadQuarters ~ Abrams Location</div></div>
</div>
<div class="row padding10">
    <div class="col-sm-12">
        <div style="text-align:center;">
            <span class="bigandboldblack">BIGGER!!&nbsp;&nbsp;&nbsp;CLEANER!!</span>
            <br />
            <br />
            <span class="bigandboldblack">Subscription Members get 20% off new comics and 15% of everything else not already on sale; Walk-ins get 10% off everything in the store all the time, excluding sale items, including special orders.</span>
            <br />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 padding10">
        8420 Abrams Rd ste 202
        <br />
        Dallas, TX 75243
        <br />
        214-341-7033
        <br />
        in between Uncle Wok <i>(fine chine cuisine)</i> and Sherwin-Williams.
        <div class="padding10">
            HOURS:
            <ul>
                <li>Monday Closed</li>
                <li>Tuesday Closed</li>
                <li>Wendnesday 11am-8pm</li>
                <li>Thursday 11am-8pm</li>
                <li>Friday 12pm-7pm</li>
                <li>Saturday 12pm-7pm</li>
                <li>Sunday 12pm - 6pm</li>
            </ul>
        </div>

    </div>
    <div class="col-sm-6 padding10 center">     
        <p class="d-xs-none"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13400.61408933717!2d-96.73889!3d32.894109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864ea00628f11bc5%3A0xa4426fa307ecb0f4!2s8420+Abrams+Rd+%23202%2C+Dallas%2C+TX+75243!5e0!3m2!1sen!2sus!4v1453047949954" width="400" height="300" frameborder="0" style="border:0" allowfullscreen></iframe></p>
        <p class="d-none d-xs-block"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13400.935686037246!2d-96.742494!3d32.891983!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864ea00628f11bc5%3A0xa4426fa307ecb0f4!2s8420+Abrams+Rd+%23202%2C+Dallas%2C+TX+75243!5e0!3m2!1sen!2sus!4v1454127314602" width="300" height="200" frameborder="0" style="border:0" allowfullscreen></iframe></p>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 big">Go Inside Awesome Comics</div>
    <div class="col-sm-6 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/storeview1.png" /></div>
    <div class="col-sm-6 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/storeview3.png" /></div>
    <div class="col-sm-6 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/storeview2.png" /></div>
    <div class="col-sm-6 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/storeview5.png" /></div>
    <div class="col-sm-12 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/storeview4.png" /></div>
    <div class="col-sm-12 padding10 text-center"><img class="img-fluid" border="0" src="/images/store/abrams/comicwall.png" /></div>
</div>