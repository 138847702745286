export class User {

    constructor() {
        this.country = 'US';
        this.state = '';
    }

    id: number; 
    firstName: string; 
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    address: string;
    city: string;
    state: string;
    postal: string;
    phone: number;
    character: string;
    originalEmail: string;
    country: string;
    emailList: boolean;
}

export interface UserToken {
    firstName: string;
    lastName: string;
    email: string;
    authentication: string;
}

export interface AwesomeToken {
    token: string;
    refreshToken: string;
}

export class CreditCard {
    constructor() {        
    }

    id: string;
    userId: number;
    name: string;
    creditCardNumber: string;
    creditCardCVC: string;
    creditCardExpiration: string;
    billingZipCode: string;
    creditCardExpMonth: string;
    creditCardExpYear: string;
}

export class PagingModel {

    constructor(_currentPage: number, _itemsPerPage: number){
        this.currentPage = _currentPage;
        this.itemsPerPage = _itemsPerPage;
    }

    currentPage: number;
    totalRecords: number;
    itemsPerPage: number;

    firstRecord() : number {
        if(this.currentPage === 1) return 0;

        return (this.currentPage - 1) * this.itemsPerPage;
    }

    lastRecord() : number {
        return this.firstRecord() + this.itemsPerPage;
    }
}
