import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[appConfirmPassword]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ConfirmPasswordDirective, multi: true }]
})
export class ConfirmPasswordDirective implements Validator, OnChanges {

    @Input('appConfirmPassword') password: string;
    private onChange: () => void;

    constructor() {
     }

    validate(control: AbstractControl): ValidationErrors {
        if(!control.value) return;
        if(!this.password) return;

        if(control.value === this.password) return;

        return {'appConfirmPassword' : control.value};
    }
    
    registerOnValidatorChange?(fn: () => void): void {
        this.onChange = fn;
    }

    ngOnChanges(changes: SimpleChanges) {
        if('password' in changes){
            if (this.onChange) this.onChange();
        }
    }
}
