<div class="row">
    <div class="col pr-3">
        <i class="fa fa-times-circle-o fa-lg cursor float-right" aria-hidden="true" mat-dialog-close></i>
    </div>
</div>
<div class="row">
    <div class="col py-2  text-center">
        <img [src]="data.image" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale" />
    </div>
</div>
<div class="row">
    <div class="col pb-3  text-center">
        Our New Secret World Headquarters! Grand Opening SALE For The New Location Less Than 1 Month Away! 
    </div>
</div>
