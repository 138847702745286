import { Component, OnInit } from '@angular/core';
import { AwesomeService } from '../shared/awesome.service';
import { LocalYokel, EbayItem } from '../shared/awesome-models';
import { MessagingLibraryService, Message } from  'angular-messaging-library';
import {ImageDialogComponent} from '../dialog/image-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
      ''
  ]
})
export class HomeComponent implements OnInit {

  localYokels: Array<LocalYokel>;
  ebayItems: Array<EbayItem>;

  constructor(private awesomeService: AwesomeService, private messageService: MessagingLibraryService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getEbayItems();
    this.getLocalYokels();
  }

  handleError(err: any) {
    this.messageService.addMessage(new Message(err))
  }

  getEbayItems(): void {
    this.awesomeService.getRandomEbay().subscribe(
      res => this.ebayItems = res,
      err => this.handleError(err)
    )
  }

  getLocalYokels(): void {
    this.awesomeService.getRandomLocalYokel().subscribe(
      res => this.localYokels = res,
      err => this.handleError(err)
    );
  }

  openDialog(imgname: string) : void {
    let dialogRef = this.dialog.open(ImageDialogComponent, {
        width: '550px',
        data: {image:  '/images/store/regal-row/' + imgname} });

    dialogRef.afterClosed().subscribe(x => {
        console.log('dialog was closed');
    })
  }

}
