import { Injectable } from '@angular/core';
import { User, AwesomeToken } from 'awesome-lib';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { catchError } from 'rxjs/operators';
import { LoginModel, ChangePasswordModel, ResendValidationModel } from './account-local-models';

@Injectable({
    providedIn: 'root'
})
export class AccountService  {

   
    _headers = { headers: { 'Content-Type':'application/json'}};

    constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

    register(user: User) : Observable<any> {
        return this.httpClient.post('/api/account/user/register', JSON.stringify(user), this._headers).pipe(catchError(this.errorHandlerService.handleError));
    }

    getUser() : Observable<User> {
        return this.httpClient.get('/api/account/user').pipe(catchError(this.errorHandlerService.handleError));
    }

    updateUser(user: User) : Observable<any> {
        return this.httpClient.put('/api/account/user/edit', JSON.stringify(user), { headers: { 'Content-Type':'application/json'}}).pipe(catchError(this.errorHandlerService.handleError));
    }

    logIn(login: LoginModel) : Observable<AwesomeToken> {
        return this.httpClient.post('/api/account/login', JSON.stringify(login), { headers: { 'Content-Type':'application/json'}}).pipe(catchError(this.errorHandlerService.handleError));
    }

    postRefreshToken(refreshToken: string) : Observable<AwesomeToken> {
        return this.httpClient.post('/api/account/token/refresh?token=' + refreshToken, null).pipe(catchError(this.errorHandlerService.handleError));
    }

    forgotPassword(email: string) : Observable<any> {
        return this.httpClient.post('/api/account/password/forgot', JSON.stringify({ 'email': email}), this.getApplicationJsonHeaders()).pipe(catchError(this.errorHandlerService.handleError));
    }

    changePassword(changePassword: ChangePasswordModel) : Observable<any> {
        return this.httpClient.post('/api/account/password/change', JSON.stringify(changePassword), this.getApplicationJsonHeaders()).pipe(catchError(this.errorHandlerService.handleError));
    }

    validateEmail(code: string) : Observable<any> {
        return this.httpClient.get('/api/account/validate/' + code).pipe(catchError(this.errorHandlerService.handleError));
    }

    resendValidation(resendValidation : ResendValidationModel) : Observable<any> {
        return this.httpClient.post('/api/account/validate/resend', JSON.stringify(resendValidation), this.getApplicationJsonHeaders()).pipe(catchError(this.errorHandlerService.handleError));
    }


    getApplicationJsonHeaders() {
        return { headers: { 'Content-Type':'application/json'}};
    }

}
