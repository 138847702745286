import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { AwesomeToken } from 'awesome-lib';
import { AwesomeTokenHandlerService } from 'awesome-lib';
import { switchMap, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { AccountService } from '../../account/shared/account.service';
import { MessagingLibraryService } from 'angular-messaging-library';

@Injectable()
export class AwesomeInterceptor implements HttpInterceptor {

    private callCount: number = 0;
    private isRefreshingToken: boolean = false;
    private refreshedTokenSubject = new Subject();
    private $token = this.refreshedTokenSubject.asObservable();


    constructor(private tokenService: AwesomeTokenHandlerService, private loaderService: LoaderService, 
        private accountService: AccountService, private messageService: MessagingLibraryService) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /*
        0. check to see if request is to get the refresh token and if so, skip all checks. 
        1. it authenticated, add bearer token
        2. if not authenticated, check if refresh token exits. if it does try getting new token. if successful, save token and add bearer token. 
            if not successful, just continue
        3. if no refresh token, continue
    
        */

        if (req.url.indexOf('/token/refresh') !== -1) {
            return this.cloneRequest(req, next, null);
        }

        if (this.callCount < 0) {
            this.callCount = 0;
        }

        if(this.callCount === 0){
            this.messageService.clearMessages();
        }


        let token: AwesomeToken = this.tokenService.getValidToken();
        if (this.tokenService.isAuthenticated() && !this.tokenService.isExpired(token.token)) {
            console.log('got valid token already');
            return this.cloneRequest(req, next, token.token);
        }
        else {
            if (token) {
                return this.doRefreshToken(token.refreshToken).pipe(switchMap(
                    () => {
                        console.log('making call now');
                        token = this.tokenService.getValidToken();
                        return this.cloneRequest(req, next, token ? token.token : '');
                    }
                ));
            }
            else {
                console.log('no token at all');
                return this.cloneRequest(req, next, null);
            }
        }
    }

    private cloneRequest(request: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
        this.callCount++;
        this.loaderService.doLoadCountAction(this.callCount);
        if (token) {
            return next.handle(request.clone({ headers: request.headers.append("Authorization", "Bearer " + token) })).pipe(finalize(() => { this.loaderService.doLoadCountAction(--this.callCount) }));
        }
        else {
            return next.handle(request.clone()).pipe(finalize(() => { this.loaderService.doLoadCountAction(--this.callCount) }));;
        }
    }

    doRefreshToken(token: string): Observable<any> {
        if (this.isRefreshingToken) {
            console.log('about to boom');
            return new Observable(o => {
                this.$token.subscribe(
                    () => {
                        console.log('boom');
                        o.next();
                        o.complete();
                    }
                )
            });
        }
        else {
            console.log('about to refresh');
            this.isRefreshingToken = true;
            return this.accountService.postRefreshToken(token).pipe(tap(
                res => {
                    console.log('refreshed returned');
                    console.log(res);
                    if (res) {
                        this.tokenService.saveToken(<AwesomeToken>res);
                    }
                    else {
                        console.log('no token - delete here');
                        this.tokenService.destroy();
                    }
                    this.isRefreshingToken = false;
                    this.refreshedTokenSubject.next();
                },
                err => {
                    console.log('refereshed returned err');
                    console.log(err);
                }
            ));
        }
    }
}

