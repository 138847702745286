import { Component, OnInit, OnDestroy } from '@angular/core';
import { AwesomeService } from '../shared/awesome.service';
import { EbayItem, EbayPaging, EbayResponse } from '../shared/awesome-models';
import { PagingModel } from 'awesome-lib';
import { MessagingLibraryService } from 'angular-messaging-library';

@Component({
    selector: 'app-ebay',
    templateUrl: './ebay.component.html',
    styles: []
})
export class EbayComponent implements OnInit, OnDestroy {

    ebayItems: EbayItem[] = Array();
    ebayResponse: EbayResponse;
    filteredItems: EbayItem[] = [];
    pagingModel: PagingModel = new PagingModel(1, 20);
    paging: EbayPaging = new EbayPaging(1, null);

    constructor(private awesomeService: AwesomeService, private messageService: MessagingLibraryService) {}

    ngOnInit() {
        this.getRecords(false);
    }

    ngOnDestroy() {
    }

    
    pageScroll() {
        if(this.paging.currentPage === this.ebayResponse.numberOfPages) return;
        this.paging.currentPage++;
        this.getRecords(false);
    }

    getRecords(resetArray: boolean) {
        console.log(JSON.stringify(this.paging));
        this.awesomeService.getEbayItems(this.paging).subscribe(
            res => {
                this.ebayResponse = res;
                if (resetArray) {
                    this.filteredItems = [];
                }
                this.filteredItems = [...this.filteredItems, ...this.ebayResponse.ebayItems];
            },
            err => this.messageService.addErrorMessage(err)
        );
    }

    onSearchChanged($event: string): void {
        this.paging.searchText = $event;
        this.paging.currentPage = 1;
        this.getRecords(true);
    }

    formatMoney(amount): number {
        return amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    };

}
