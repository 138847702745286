import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import {ConfirmPasswordDirective} from './directives/confirm-password.directive';
import {AppLibraryModule} from '../app-library.module';
import { LoaderComponent } from './components/loader/loader.component'


@NgModule({
  imports: [
    CommonModule, 
    PaginationModule.forRoot(), 
    FormsModule, CreditCardDirectivesModule, AppLibraryModule
  ],
  declarations: [CreditCardComponent, ConfirmPasswordDirective, LoaderComponent], 
  exports: [CommonModule, FormsModule, PaginationModule, CreditCardComponent, CreditCardDirectivesModule, ConfirmPasswordDirective, AppLibraryModule, LoaderComponent]
})
export class SharedModule { }
