<div class="row">
    <div class="col">
        <div class="pageheader">Awesome Comics Happenings</div>
    </div>
</div>
<div class="row">
    <div class="col">
        Stay up to date on the latest happenings at Awesome
        Comics by signing up for our <a routerLink="/maillist">mailing list</a>.
    </div>
</div>

<div style="min-height: 300px;"></div>