<div class="row">
    <div class="col">
        <div class="pageheader">Gift Cards</div>
    </div>
</div>
<div class="row">
    <div class="col">
        <span class="bigandboldblack">E-Gift Cards and Physical Gift Cards</span>
        <p>E-Gift cards and physical gift cards are the perfect holiday treat for the comic book & toy fanatic in your life!</p>
        <p>For the e-gift card, <a href="https://squareup.com/gift/4Q7RQN8BK594Z/order" target="_blank">simply select the design you like on our display screen</a>, then choose any dollar amount & enter in the email of the person you want to send the e-gift card to and that’s it…e-z peasy!</p>
        <p>You can even call us at the shop @ 214-341-7033 and set up a physical gift card or e-gift card and we’ll send it to whoever you want.</p>
        <p>We also still have our custom paper gift certificates available as well for you traditional types!</p>
        <p>Now we have 3 nice & easy ways to give your loved ones the gift of comics & toys! Give us a call or <a href routerLink="/store/abrams">come by the shop</a> and we’ll take care of you</p>
        <p>
            <a mat-flat-button color="awesome" href="https://squareup.com/gift/4Q7RQN8BK594Z/order" target="_blank">Purchase your E-Gift Card</a>
        </p>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p class="bigandboldblack">How easy is it to purchase a gift card in our store?</p>
        <p>For your physical gift card, just pick your card and money to it</p>
    </div>
    <div class="col-12 col-sm-3 padding10"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step1.png" /></div>
</div>
<div class="row">
    <div class="col-12">
        <p class="bigandboldblack">For your e-gift card, first pick your design from our different options</p>
    </div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step2_1.png" /></div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step2_2.png" /></div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step2_3.png" /></div>
</div>
<div class="row">
    <div class="col-12">
        <p>Then just finish up with the amount and email address</p>
    </div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step3.png" /></div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step4.png" /></div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step5.png" /></div>
    <div class="col-12 col-sm-3 padding10 text-center"><img class="img-fluid" style="max-height: 300px;" border="0" src="/images/biz/giftcardprocess/step6.png" /></div>
</div>

<div style="min-height: 100px;"></div>