<div class="row">
    <div class="col-sm-12"><div class="pageheader">Awesome Mailing List</div></div>
</div>
<lib-messaging-library></lib-messaging-library>
<div class="row">
    <div class="col-12">
        Sign up for the Awesome Comics Mailing List and receive notifications on sales and upcoming events.
        <br /><br />
        <p><strong>All fields are required</strong></p>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <form name="frmMailList" #mailForm="ngForm">
            <div class="form-group">
                <label label-for="EmailAddress">Email address</label>
                <input type="email" class="form-control" [(ngModel)]="maillist.emailAddress" required name="EmailAddress" ng-minlength="3" #EmailAddress="ngModel" /> 
                <span [hidden]="EmailAddress.valid || EmailAddress.pristine" class="alert alert-danger">Email address is required</span>
            </div>
            <div class="form-group">
                <label label-for="FirstName">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="maillist.firstName" required name="FirstName" #FirstName="ngModel" />
                <span [hidden]="FirstName.valid || FirstName.pristine" class="alert alert-danger">First name is required</span>
            </div>
            <div class="form-group">
                <label label-for="LastName">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="maillist.lastName" required name="LastName" #LastName="ngModel" />
                <span [hidden]="LastName.valid || LastName.pristine" class="alert alert-danger">Last name is required</span>
            </div>
            <div class="form-group-sm xform">
                <label label-for="City">City</label>
                <input type="text" class="form-control"  [(ngModel)]="maillist.city" name="City" />
            </div>
            <button class="btn btn-primary" [disabled]="isBusy || !mailForm.valid" (click)="submit()">Submit <span [hidden]="!isBusy" class="fa fa-circle-o-notch fa-spin"></span></button>
        </form>
    </div>
</div>

<div style="min-height: 100px;"></div>