
<div class="row">
  <div class="col-sm-12">
      <div class="pageheader">Online Comics</div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
      Welcome, cautious consumer, to our online crypt of cookoo comics and unforgettable funny-stuff! We do hope you enjoy the fruits
      of our pain-staking labor, and feel charitable enough to purchase one, or all of our line of
      periodical pamphlets available exclusively in the <a routerLink="/happymedium">Happy Medium</a>
      section of this here site.  Yes, that's right, for less than the price of a single gallon of
      gas (or a pack a' smokes) you too can have one of our handsome humor tracts to further
      clutter up and complicate your life with!!
      <br />
      <ul>
          <li>The goodly amount of comics here are still W.I.P.'s (works in progress), and might seem to be a tab bit under-wrought and unfinished.  Some of 'em still need color, corrections, and/or digital cosmetics before they can be experienced with the full sound 'n' fury they were originally intended to posses.</li>
          <li>As you might soon deduct, new material gets added every couple, 3 months, or whenever outside submissions are made.  If you're interested in submitting your own material and joining the renowned ranks of the Local Yokels illuminati, please send a moderately expounded email to <a href="mailto:awesome_comics@yahoo.com">awesome_comics@yahoo.com</a>, and someone special will get back with you in due course.</li>
          <li>A handful of strips are what we call "comic-covers" that J. Ewin' felt impelled to ape from other more established "artists" principally for the sole purpose of having semi-original artwork to hang up when you can't otherwise afford to.  So naturally, all of the works listed as such are copyrighted to the respective creators!  Modest alterations have been made to add a personal touch to the efforts.</li>
          <li>Also, we've recently added the amenity of colored comics to our ever-expanding library of laughs, spared no expense too you of course.  Just Click on the link below to plunge into the inescapable void of over-embellished nonsense and near-miss musings!</li>
      </ul>
      <br />
  </div>
</div>
<div class="row paddingBottom10">
  <div class="col-sm-4">   
      <lib-awesome-lib-text-debounce textPlaceholder="Search Online Comics" [textModel]="searchText" (textChanged)="searchChanged($event)"></lib-awesome-lib-text-debounce> 
  </div>
  <div class="col-sm-4"></div>
  <div class="col-sm-4">
      <select class="form-control pull-right" [ngModel]="selected" (ngModelChange)="filterChanged($event)">
          <option *ngFor="let opt of selectOptions" [value]="opt.value">{{opt.text}}</option>
      </select>
  </div>
</div>
<div *ngIf="filteredLocals && filteredLocals.length > 0">
    <div class="row">
    <div class="col-sm-12">
        <div class="d-none d-sm-block col-sm-12">
            <div class="table-responsive">
                <table class="table table-condensed table-stripe">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Title</th>
                            <th>Creator</th>
                            <th>Posted</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of filteredLocals">
                        <tr>
                            <td>

                                <a [routerLink]="['../onlinecomic/', item.id]" [queryParams]="{s:selected, p:pagingModel.currentPage, f:searchText}">
                                    <img class="img-thumbnail" [src]="'/images/local/' + item.image" /></a>

                            </td>
                            <td><a [routerLink]="['../onlinecomic/', item.id]" [queryParams]="{s:selected, p:pagingModel.currentPage, f:searchText}"><span>{{item.title}}</span></a></td>
                            <td><span>{{item.creator}}</span></td>
                            <td><span>{{item.created | date: 'MMM d, yyyy'}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="d-block d-sm-none">
            <div class="table-responsive">
                <table class="table table-condensed table-stripe">
                    <tbody *ngFor="let item of filteredLocals">
                        <tr>
                            <td>
                                <a [routerLink]="['../onlinecomic/', item.id]" [queryParams]="{s:selected, p:currentPage, f:searchText}">
                                <img class="img-thumbnail" [src]="'/images/local/' + item.image" /></a>

                            </td>
                            <td>
                                <a [routerLink]="['../onlinecomic/', item.id]" [queryParams]="{s:selected, p:currentPage, f:searchText}"><span>{{item.title}}</span></a>
                                <br />
                                Creator:&nbsp;<span>{{item.creator}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-12 center">
        <lib-awesome-lib-pager [pageModel]="pagingModel" (pageChanged)="pageChanged($event)"></lib-awesome-lib-pager>
    </div>
    </div>
</div>
<div *ngIf="filteredLocals && filteredLocals.length === 0 && searchText">
    <div class="row">
        <div class="col">
            No items matched your search and/or filter selection. 
            <div style="min-height: 100px;"></div>
        </div>
    </div>
</div>