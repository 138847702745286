export class EbayItem {
    description: string;
    itemId: number;
    quantity: number;
    startPrice: number;
    timeLeft: string;
    title: string;
    convertedStartPrice: number;
    startTime: Date;
    endTime: Date;
    viewItemUrl: string;
    listingStatus: string;
    pictureUrl: string;
    price: number;
}


export class LocalYokel {
    id: number; 
    lastMod: Date;
    created: Date;
    title: string;
    image: string;
    adult: boolean;
    creator: string;
    creatorContact: string;
    pages: number;
    active: boolean;
    color: boolean;
    localYokelItems: Array<LocalYokelItem>
}

export class LocalYokelItem {
    id: number; 
    lastMod: Date;
    created: Date;
    localYokelId: number;
    image: string;
    page: number;
    views: number;
}

export class LocalYokelFlat {
    id: number; 
    title: string;
    creator: string;
    creatorContact: string;
    pages: number;
    image: string;
    page: number;
    imageId: number;
}

export class Maillist {

    constructor() {
        this.emailAddress = '';
        this.firstName = '';
        this.lastName = '';
        this.city = '';
    }

    emailAddress: string;
    firstName: string;
    lastName: string;
    city: string;
}

export class WishlistResult {
    uid: string;
    fullName: string;
    city: string;
    wishlistItems: Array<WishlistItem>
}

export class WishlistItem {
    id: number;
    item: string;
}

export class AwesomeQueryParams {

    constructor(_url, _params){
        this.path = _url;
        this.params = _params;
    }

    path: string;
    params: string;
}

export class EbayPaging {

    constructor(_page, _searhText){
        this.currentPage = _page;
        this.searchText = _searhText || null;
    }

    currentPage: number;
    searchText: string;
}

export class EbayResponse {
    numberOfEntries: number;
    numberOfPages: number;
    currentPage: number;
    ebayItems: Array<EbayItem>;
}