import { NgModule } from '@angular/core';
import { AppRoutingModule, components } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLibraryModule } from './app-library.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button'

@NgModule({
    declarations: [
        AppComponent, components
    ],
    imports: [
        CoreModule,
        SharedModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppLibraryModule,
        InfiniteScrollModule, 
        MatCardModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
    ],
    bootstrap: [AppComponent],
    providers: []
})


export class AppModule { }
