import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalYokel, EbayItem, LocalYokelFlat, Maillist, WishlistResult, EbayPaging, EbayResponse } from './awesome-models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../core/services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AwesomeService {

    constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }


    getRandomLocalYokel(): Observable<Array<LocalYokel>> {
        return this.httpClient.get('/api/localYokel/random').pipe(catchError(this.errorHandlerService.handleError));
    }

    getRandomEbay(): Observable<Array<EbayItem>> {
        return this.httpClient.get('/api/ebay/random').pipe(catchError(this.errorHandlerService.handleError));
    }

    getLocalYokels(): Observable<Array<LocalYokel>> {
        return this.httpClient.get('/api/localYokel/locals').pipe(catchError(this.errorHandlerService.handleError));
    }

    getSelectedLocalYokel(id: number): Observable<LocalYokel> {
        return this.httpClient.get('/api/localYokel/selected/' + id).pipe(catchError(this.errorHandlerService.handleError));
    }

    setPageView(imageId: number, page: number): Observable<any> {
        return this.httpClient.post('/api/localYokel/update', { 'imageId': imageId, 'page': page }).pipe(catchError(this.errorHandlerService.handleError));
    }

    sendMailList(maillist: Maillist): Observable<any> {
        return this.httpClient.post('/api/awesome/ymlp', maillist).pipe(catchError(this.errorHandlerService.handleError));
    }

    getEbayItems(paging: EbayPaging): Observable<EbayResponse> {
        return this.httpClient.post('/api/ebay/listing', JSON.stringify(paging), {headers: {'Content-Type': 'application/json'}}).pipe(catchError(this.errorHandlerService.handleError));
    } 

}
