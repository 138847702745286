<lib-messaging-library></lib-messaging-library>
<div class="row pt-2">
    <div class="col">
        <div class="text-center event-bar">
        <a href routerLink="/giftcertificates" class="event-bar-link">New E-Gift Card And Physical Gift Cards Available Now.</a>
        </div>
    </div>
</div>

<div class="row pt-2">
    <div class="col-12">
        <p class="bigandboldblack font20">Awesome Comics' new store is NOW OPEN Thursday - Sunday!</p></div>
    <div class="featuredtext col-12 col-lg-8">
        <p>Good news, everybody!</p>
        <p>Awesome Comics new location - the Secret World Headquarters is NOW OPEN for business Thursday through Sunday! Come see what all the excitement is about! This is the hottest new comic store in the DFW area…perhaps in the world! Enjoy a most unique comic buying experience! A spacious and comfy atmosphere that's a great place to spend time sifting through back issue boxes galore! We have comic books for every budget! Thousands and thousands of comics starting @ $1 on up! There's KEY showcase & wall books too along with tons of COMIC SETS, MAGAZINES, TPBs/HCs & TOYS, all at awesome prices! We'll do extra discount sales there every 2-3 months, but we're making great deals too everyday that we're open.</p>
        <p>We're also actively buying comic collections large & small and this will be the location we'll house most of our new incoming inventory.</p>
        <p>This is a nice 2-story office building that's easy to find and there's plenty of parking spaces in front and on the side of the building. There's handicap parking, ramps and an elevator for easy access to our space upstairs. Hope to see y'all there soon!</p>
        <p> 
            Awesome Comics Online
            <br />
            The Regal Building
            <br />
            <a href="https://www.google.com/maps/place/1720+Regal+Row,+Dallas,+TX+75235/@32.836149,-96.87204,13z/data=!4m6!3m5!1s0x864e9c50cff26ffd:0x396a294392230c55!8m2!3d32.836149!4d-96.8720398!16s%2Fg%2F11b8v53dl_?hl=en&entry=ttu" target="_blank">1720 Regal Row Ste #210 (2nd Floor)</a>
           
            <br />
            Dallas, TX 75235
            <br />
            New Store Cell # - 682-395-7429
        </p>
        
        <p>Store Hours:
            <br/>Thursday 12-7
            <br/>Friday 12-7
            <br/>Saturday 12-6
            <br/>Sunday 12-6
        </p>

        <p>Check out the <a href routerLink="/store/regal-row" fragment="photos">updated pictures of the new space</a> and we'll add a video tour in the next week or so to give you an idea of what we have going on there!</p>
       
       <p>We're getting ready to expand our online presence and launch some new projects selling on Whatnot, Facebook Live and more! We'll have a lot more info about these upcoming endeavors very soon!</p>
       <p>Cheers,</p> 
        <p>Jake & Co.</p>
    </div>
    <div class="col-12 col-lg-4 text-center pb-1">
        <div class="col-lg-12 col-sm-12">
        <img class="img-fluid"  src="/images/store/regal-row/biz_card.jpg"  alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        
        <div class="d-none d-sm-block col-lg-12 mt-4">
            <div class="row mt-4">
                <div class="col-md-6 col-lg-12 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_21.png')" src="/images/store/regal-row/ns_21_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
                <div class="col-md-6 col-lg-12 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_19.png')" src="/images/store/regal-row/ns_19_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
                <div class="col-md-6 col-lg-12 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_18.png')" src="/images/store/regal-row/ns_18_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
                <div class="col-md-6 col-lg-12 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_17.png')" src="/images/store/regal-row/ns_17_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
                <div class="col-md-6 col-lg-12 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_16.png')" src="/images/store/regal-row/ns_16_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div> 
            </div>
        </div>
    </div>
</div>
<!-- <div class="row">
    <div class="col-lg-6 col-sm-12 text-center">
        <p>Take a tour of the new location</p>
        <object width="500px" 
        height="400px"  data="https://www.awesomecomicsonline.com/regal_row.mp4" type="video/mp4">
        </object>
    </div>
    <div class="d-none d-sm-block col-lg-6 mt-4">
        <div class="row mt-4">
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_21.png')" src="/images/store/regal-row/ns_21_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_19.png')" src="/images/store/regal-row/ns_19_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_18.png')" src="/images/store/regal-row/ns_18_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_17.png')" src="/images/store/regal-row/ns_17_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_16.png')" src="/images/store/regal-row/ns_16_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>
            <div class="col-md-6 text-center pb-3"><img style="height:100px;" class="cursor" (click)="openDialog('ns_15.png')" src="/images/store/regal-row/ns_15_thumb.png" alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." /></div>   
        </div>
    </div>
</div> -->
<div class="row pt-3">
    <div class="col pl-5 align-middle">
        <a href routerLink="/ebay"><img src="/images/biz/ebay.png"
                alt="Awesome Comics auctions on Ebay. Check out all the auctions we have on Ebay and snag you some great deals from your living room"
                border="0" /></a> <a href routerLink="/ebay">view all current Ebay offerings</a>
    </div>
</div>

<div class="row">
    <div class="col-sm-6 col-md-3 pb-2" *ngFor="let item of ebayItems">
        <div class="row pb-1">
            <div class="col d-flex align-items-center justify-content-center img-fpg-div">
                <a href="{{item.viewItemURL}}" target="_blank"><img border="0" [src]="item.pictureURL"
                        class="img-thumbnail img-awesome" /></a>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex text-center align-middle">
                <a href="{{item.viewItemURL}}" target="_blank"><span [innerHtml]="item.title"></span></a>
            </div>
        </div>
    </div>
</div>
<div class="row pt-2">
    <div class="col-md-6 col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>The Awesome Advantage</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Awesome Comics stocks a massive selection of competetively priced back issues, statues, trades and
                    action figures. And every person
                    recieves a <b>10% walk-in discount</b> (<a href routerLink="/subscription">subscription service
                        members recieve 20%</a>).
                    In addition, we also have a large, quality offering of $1 and .50&cent; comics.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a href routerLink="/account/register">Register for Subscription Service</a>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="w-100 d-md-none d-sm-block pt-4"></div>
    <div class="col-md-6 col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>The Awesome Comics Subscription Service</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Never miss an issue of your favorite comic ever again. With the Awesome Comics Subscription Service,
                    your comics are waiting on you every week.
                    In addition, you get <b>20% Off All New Comics - Subscription and Non.</b> and 15% off everything
                    else
                    in the store that is not on sale.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a routerLink="/account/login">Start saving today</a>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div class="row pt-4">
    <div class="col-md-6 col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Follow Awesome Comics on Facebook</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    <a href="http://www.facebook.com/awesomecomics" target="_blank"><img
                            src="/images/fpg/facebook_icon.jpg"
                            alt="Awesome Comics on Facebook. Like us and get special offers only available to our Facebook friends"
                            class="pull-right" border="0" /></a>
                    Are you our friend on Facebook? As we settle in to our 26th year in business, we'll be posting
                    exclusive
                    deals to our FaceBook page for good stuff we get in like vintage and modern comics as well as cool
                    toys! Our
                    FaceBoook page
                    is updated instantly with new comic collections brought into the store.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <a href="http://www.facebook.com/awesomecomics" target="_blank">Like us on Facebook</a>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="w-100 d-md-none d-sm-block pt-4"></div>
    <div class="col-md-6 col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Online Comics</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>Showcasing the freshest young talent from the desolate Dallas comic book scene.</p>
                <div class="row">
                    <div class="col" *ngFor="let comic of localYokels">
                        <div [routerLink]="['../onlinecomic/', comic.id]">
                            <img class="img-thumbnail localComicsThumb"
                                alt="10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale. Mail Order/In-Store Comic Book Subscription Service, Awesome Comics Featured Local Comic Artist, Independent Comics, Dallas Tx, Dallas Comic Store"
                                border="0" [src]="'/images/local/' + comic.image" />
                        </div>
                        <a href [routerLink]="['../onlinecomic/', comic.id]"><span
                                class="small">{{comic.title}}</span></a>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <a routerLink="/onlinecomics">More Online Comics</a>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div class="row pt-4">
    <div class="col-12 col-md-6 text-center">

        <mat-card>
            <mat-card-content>
                <p>
                    <img src="/images/happymedium/hm_blacklogo.png" class="img-fluid" alt="Happy Medium Comics" />
                </p>

                    <div class="text-center">
                        <a routerLink="/happymedium">Happy Medium Comics</a> | <a routerLink="/happymedium/aldente">Al
                            Dente</a> | <a routerLink="/happymedium/footnotes">The Footnotes</a> | <a routerLink="/happymedium/riffschlitz">Riff Schlitz</a>
                    </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>