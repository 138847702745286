<a name="discount"></a>
<div class="row">
    <div class="col-sm-12">
        <div class="pageheader">Awesome Comics Discount Comics</div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <p>
            Along with our massive selection of competitively priced back issues, trades, figures and statues, Awesome
            Comics
            is always increasing it's already large offering of $1.00 comics and .50&cent comics.
        </p>
        <p>
            We're talking some quality stuff here that is ideal to help you fill in gaps in your current collection, or
            if you
            are just
            starting out, help build your new collection.
        </p>
        <p>
            Come by our <a href routerLink="../store">store</a> and browse thru our bins.
        </p>
    </div>
</div>

<hr>

<a name="toys"></a>
<div class="row">
    <div class="col-sm-12">
        <div class="pageheader">Toys</div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        Awesome Comics has a large selection of Toys for all ages. Action Figures, Statues, Die Casts, Funko (POP!,
        Vinyl
        and Wobblers), etc- includes new items and your classic favorites.
        <br />
        Stop by the store anytime or catch us at one of the many conventions we attend.
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/jurrasicpark.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/modok.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/drdoom_sandman.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/ashoka.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/gambit_dd_brb.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/spidey_ironman.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/Toys/jack_galactus.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
    </div>
</div>
<hr>
<a name="silver"></a>
<div class="row">
    <div class="col-sm-12">
        <div class="pageheader">Silver, Bronze & Modern Age Comics</div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        Awesome Comics has recently recieved a large collection of Silver Age comics. We have a large collection of both
        Marvel and DC comics including Batman, The Fantastic Four, Justice League, The Avengers and more.
    </div>
</div>

<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/hulk_162.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/tmnt_ronin_5.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/tmnt_81.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/ff_57.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/avengers_annual_10.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/xmen_6.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/xmen_109.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/xmen_50.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/silversurfer_1.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/venom_protector_1.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/batman_232.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/spiderman_252.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/starwars_heir_1b.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/starwars_heir_1a.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/batman_52_1.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/batman_423.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/ultimatefallout_4.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/tales_suspense_94.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-12 text-center padding10">
        <div class="awesomeimagepadding">
            <img class="img-fluid  center-block" src="/images/comics/captainamerica_109.jpg"
                alt="Awesome Comics, Dallas' Premiere Comic Shop, has a large collection of Toys - Action Figures, Statues, Die Casts, etc. Star Wars, Marvel, DC, Wolverine, Batman, Iron Man, Hulk, Spider-Man" />
        </div>
    </div>
    <div class="col-sm-6 col-12 text-center padding10">
    </div>
</div>