import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PagingModel } from './awesome-lib.models';

@Component({
  selector: 'lib-awesome-lib-pager',
  template: `
    <pagination class="pagination mx-auto" [totalItems]="pageModel.totalRecords" [(ngModel)]="pageModel.currentPage"
        [rotate]="true" [boundaryLinks]="true" [itemsPerPage]="pageModel.itemsPerPage" (pageChanged)="onPageChanged($event)"
        (numPages)="totalNumPages=$event" [maxSize]="maxsize">
    </pagination>
    <div class="row">
      <div class="col">
          <div class="text-center pagination-text">
              Viewing page {{pageModel.currentPage}} of {{totalNumPages}}
          </div>
      </div>
    </div>
  `,
  styles: []
})
export class AwesomeLibPagerComponent  {

  @Input() pageModel: PagingModel;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  maxsize: number = 15;
  totalNumPages : number = 0;
  
  constructor() { }

  onPageChanged(event: PageChangedEvent) : void {
    this.pageChanged.emit(event.page);
  }

}
