import { Injectable } from '@angular/core';
import { AwesomeToken, UserToken } from './awesome-lib.models';
import { JwtHelperService } from '@auth0/angular-jwt';


const helper = new JwtHelperService();
const _AwesomeToken = "AwesomeToken";
const _AdminToken = "AdminToken";

@Injectable({
    providedIn: 'root'
})
export class AwesomeTokenHandlerService {

    constructor() { }

    destroy() {
        this.deleteToken(_AwesomeToken);
    }

    destroyAdminToken() {
        this.deleteToken(_AdminToken)
    }

    isAuthenticated(): boolean {
        let token: AwesomeToken = this.getValidToken();
        return !token ? false : !this.isTokenExpired(token.token);
    }

    isAdminAuthenticated(): boolean {
        let token: AwesomeToken = this.getAdminToken();
        return !token ? false : !this.isTokenExpired(token.token);
    }

    saveToken(token: AwesomeToken): void {
        this.saveTokenType(_AwesomeToken, token);
    }

    saveAdminToken(token: AwesomeToken): void {
        this.saveTokenType(_AdminToken, token);
    }

    getAuthenticatedUser(): UserToken {
        let token: AwesomeToken = this.getValidToken();
        if (token) {
            return helper.decodeToken(token.token) as UserToken;
        }
        return null;
    }

    isExpired(token: string): boolean {
        if (token) {
            return this.isTokenExpired(token);
        }
        return true;
    }

    getValidToken(): AwesomeToken {
        let token = localStorage.getItem(_AwesomeToken);

        if (token) {
            return JSON.parse(token);
        }

        return null;
    }

    getAdminToken(): AwesomeToken {
        let token = localStorage.getItem(_AdminToken);

        if (token) {
            return JSON.parse(token);
        }

        return null;
    }

    logout(): void {
        this.deleteToken(_AwesomeToken);
    }

    adminLogout() : void {
        this.deleteToken(_AdminToken);
    }

    private isTokenExpired(token: string): boolean {
        return helper.isTokenExpired(token, 30)
    }

    private deleteToken(name: string): void {
        localStorage.removeItem(name);
    }

    private saveTokenType(name: string, token: AwesomeToken) : void {
        localStorage.setItem(name, JSON.stringify(token));
    }
}
