import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubscriberService {

    //observable source
    private isLoggedInSource = new Subject<boolean>();
    //observable stream
    loggedInObservable$ = this.isLoggedInSource.asObservable();

    constructor() { }

    annouceLoggedIn(isLoggedIn: boolean) : void {
        this.isLoggedInSource.next(isLoggedIn);
    }

}
