import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { LocalYokel, LocalYokelFlat, LocalYokelItem} from '../shared/awesome-models';
import {AwesomeService} from '../shared/awesome.service';
import * as _ from 'underscore';
import {PagingModel} from 'awesome-lib';
import { MessagingLibraryService } from 'angular-messaging-library';

@Component({
  selector: 'app-local-comic',
  templateUrl: './local-comic.component.html',
  styles: []
})
export class LocalComicComponent implements OnInit {

  comic : LocalYokel;
  selectedId: number;
  localPaging: LocalPaging = {};
  pagingModel : PagingModel = new PagingModel(1, 1);
  comicImage: LocalYokelItem;

  constructor(private awesomeService: AwesomeService, private activatedRoute: ActivatedRoute, private router: Router, private messageService: MessagingLibraryService) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.localPaging.s = params.get('s') || '';
      this.localPaging.f = params.get('f') || '';
      this.localPaging.p = +params.get('p') || 1;
    });
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.selectedId = +params.get('id')
    });
    if(this.selectedId && this.selectedId > 0) {
      this.getSelectedLocalYokel();
    }
    else {
      //do something here
    }
  }

  handleError(err: any) {
    this.messageService.addErrorMessage(err);
  }


  getSelectedLocalYokel() {
    this.awesomeService.getSelectedLocalYokel(this.selectedId).subscribe(
        res => {
         this.comic = res;         
         this.pagingModel.totalRecords = this.comic.pages;
         this.setImage();
        }, 
        err => this.handleError(err)
    );
  }

  updatePageView(pageId: number, page : number) {
    this.awesomeService.setPageView(pageId, page).subscribe(
      err => this.handleError(err)
    )
  }

  private setImage() : void {
    this.comicImage = _.find(this.comic.localYokelItems, (x : LocalYokelItem) => x.page == this.pagingModel.currentPage);
  }

  redirect() : void {
    console.log(this.localPaging);
    this.router.navigate(['../../onlinecomics'], { queryParams: {'p': this.localPaging.p, 'f': this.localPaging.f, 's': this.localPaging.s} });
  }

  pageChanged($event:number): void {
    this.pagingModel.currentPage = $event;
    this.setImage();    
    this.updatePageView(this.comicImage.id, this.pagingModel.currentPage);
  }
}


class LocalPaging {
  p?: number;
  f?: string;
  s?: string;
}

