<div class="row">
    <div class="col">
        <div class="pageheader">Awesome Comics Ebay Listing</div>
    </div>
</div>
<div class="row">
    <div class="col">
        <p><a href="http://www.ebay.com/str/awesomecomicsonline" target="_blank">Visit The Awesome Comics Ebay store</a></p>
    </div>
</div>
<div class="row paddingBottom10 align-items-center">
    <div class="col-6">    
        <lib-awesome-lib-text-debounce textPlaceholder="Search Ebay Listings" (textChanged)="onSearchChanged($event)"></lib-awesome-lib-text-debounce> 
    </div>
    <div class="col-3">
    </div>
    <div class="col">
        <img src="/images/biz/ebay.png"
            alt="Awesome Comics auctions on Ebay. Check out all the auctions we have on Ebay and snag you some great deals from your living room"
            class="pull-right" border="0" />
    </div>
</div>
<div class="row pb-5 align-items-center">
    <div class="col">
        <span *ngIf="filteredItems && filteredItems.length > 0 && !paging.searchText">
            We currently have {{ebayResponse.numberOfEntries}} items in our Ebay store. Search our Ebay listings using the search textbox above. 
        </span>        
        <span *ngIf="filteredItems && filteredItems.length > 0 && paging.searchText">
            We currently have {{ebayResponse.numberOfEntries}} items in our Ebay store that match your search for <i>{{paging.searchText}}</i>. 
        </span>
    </div>
</div>
<div class="row">
    <div class="col">
        <div *ngIf="filteredItems && filteredItems.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="300" (scrolled)="pageScroll()">
            <div *ngFor="let item of filteredItems">
                <div class="row paddingBottom10">
                    <div class="col col-sm-2">
                        <a [href]="item.viewItemURL" target="_blank">
                            <img border="0" [src]="item.pictureURL" class="img-thumbnail" />
                        </a>
                    </div>
                    <div class="col">
                        <a [href]="item.viewItemURL" target="_blank"><span class="ebayTitle">{{item.title}}</span></a>
                        <br />
                        <span><b>Price:</b> ${{item.startPrice}}</span>
                        <br />
                        <span><b>Quantity Available:</b> {{item.quantity}}</span>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ebayResponse && ebayResponse.ebayItems && ebayResponse.ebayItems.length === 0 && paging.searchText">
            No items matched your search for <i>{{paging.searchText}}</i>
            <div style="min-height: 100px;"></div>
        </div>
        <div *ngIf="ebayResponse && (!ebayResponse.ebayItems || ebayResponse.ebayItems.length === 0) && !paging.searchText">
            Currently we have no items on Ebay. Please check back here or keep an eye on our <a
                href="http://www.ebay.com/str/awesomecomicsonline" target="_top">Ebay store</a>
        </div>
    </div>
</div>
<div class="row">
    <div class="col justify-content-center">

    </div>
</div>