import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {LocalYokel} from '../shared/awesome-models';
import {AwesomeService} from '../shared/awesome.service';
import {PagingModel} from 'awesome-lib';
import {ChangeDetectorRef} from '@angular/core';


@Component({
  selector: 'app-local',
  templateUrl: './local.component.html',
  styles: []
})
export class LocalComponent implements OnInit {

  locals : Array<LocalYokel>;
  filteredLocals : Array<LocalYokel>;
  selected: string = 'A';
  searchText:string;
  pagingModel: PagingModel = new PagingModel(1, 25);
  selectOptions = [
      {value: 'A', text: 'Color & Black/White'},
      {value: 'BW', text: 'Black/White Only'},
      {value: 'C', text: 'Color Only'}
  ]

  constructor(private awesomeService : AwesomeService, private activatedRoute: ActivatedRoute, private changeDetectorRef : ChangeDetectorRef) {  
  }

  ngOnInit() {
    let keys : string[] = this.activatedRoute.snapshot.queryParamMap.keys;
    if(keys.includes('s')) {
      this.selected = this.activatedRoute.snapshot.queryParamMap.get('s');
    }
    if(keys.includes('p')) {      
      this.pagingModel.currentPage = +this.activatedRoute.snapshot.queryParamMap.get('p');      
    }
    if(keys.includes('f')) {
      this.searchText = this.activatedRoute.snapshot.queryParamMap.get('f');
    }
    this.getLocals();
  }

  handleError(err: any) {
    console.log(err);
  }

  getLocals() : void {
    this.awesomeService.getLocalYokels().subscribe(
      res => { 
        this.locals = res;
        this.filterComics();
        //this.filterChanged(this.selected);
      }, 
      err => this.handleError(err)
    )
  }

  private filterComics() {
    switch (this.selected) {
      case 'BW':
        this.filteredLocals = this.locals.filter(x => !x.color);
        break;
      case 'C':
        this.filteredLocals = this.locals.filter(x => x.color);
        break;
      default: 
        this.filteredLocals = this.locals;
    }   
    if(this.searchText) {
      this.filteredLocals = this.filteredLocals.filter(x => x.title.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1 || 
            x.creator.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    }
    
    this.pagingModel.totalRecords = this.filteredLocals.length || 0;
    this.filteredLocals = this.filteredLocals.slice(this.pagingModel.firstRecord(), this.pagingModel.lastRecord());
  }

  searchChanged($event: string): void {
    this.searchText = $event;    
    this.preFilter();
  }

  filterChanged($event: string) : void {
    this.selected = $event;  
    this.preFilter();
  }

  preFilter() : void {  
    this.pagingModel.currentPage = 1;
    this.changeDetectorRef.detectChanges();
    this.filterComics();
  }

  pageChanged($event: number) : void {
    this.pagingModel.currentPage = $event;
    this.filterComics();
  }

}
