import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubscriberService } from './core/services/subscriber.service';
import { AwesomeTokenHandlerService } from 'awesome-lib';
import { Router, NavigationStart,  NavigationEnd } from '@angular/router';
import { MessagingLibraryService } from 'angular-messaging-library';
import { CoreService } from './core/services/core.service';
import { AwesomeQueryParams } from './awesome/shared/awesome-models';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'app';
    year: number = new Date().getFullYear();
    isAuthenticated: boolean = false;
    name: string;
    @ViewChild('awesomeNav', { static: true }) awesomeNav: ElementRef;

    constructor(private subscriberService: SubscriberService, private tokenService: AwesomeTokenHandlerService, private router: Router,
        private messaegService: MessagingLibraryService, private coreService: CoreService) {
        this.subscriberService.loggedInObservable$.subscribe(
            x => this.checkAuthentication()
        )

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let parsedUrl = this.router.parseUrl(this.router.url);
                if (Object.keys(parsedUrl.queryParams).length > 0) {
                    let url = this.router.url.split('?')[0].substr(1);  //this.trimQuerystring(this.router.url);
                    if (url) {
                        let awesomeParams: AwesomeQueryParams = new AwesomeQueryParams(url, JSON.stringify(parsedUrl.queryParams));
                        this.coreService.postQueryParams(awesomeParams).subscribe(
                            () => console.log('sent'),
                            error => console.log(error)
                        )
                    }
                }
            }
        });
    }

    ngOnInit() {
        //check to see if token is available
        this.checkAuthentication();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (!!event.url && event.url.match(/^\/#/)) {
                    this.router.navigate([event.url.replace('/#', '')]);
                }
            }
        });
    }

    navBarTogglerIsVisible() {
        return this.awesomeNav.nativeElement.offsetParent !== null;
    }

    collapseNav() {
        if (this.navBarTogglerIsVisible()) {
            this.awesomeNav.nativeElement.click();
        }
    }

    checkAuthentication(): void {
        if (this.tokenService.isAuthenticated()) {
            this.isAuthenticated = true;
            this.name = this.tokenService.getAuthenticatedUser().firstName;
        }
        else {
            this.isAuthenticated = false;
            this.name = '';
        }
    }

    logout(): void {
        try {
            this.tokenService.logout();
            this.isAuthenticated = false;
            this.router.navigate(['/']);
        } catch (error) {
            this.messaegService.addErrorMessage(error);
        }
    }
}
