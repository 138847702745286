import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errMsg: string;
    console.log(error);
    if (error.error instanceof Error) {
      errMsg = error.error.message;
    }
    else {
      errMsg = error.error || (error.message || "an error has occurred");
    }

    return throwError(errMsg);
  }
}
