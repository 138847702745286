<div class="row">
  <div class="col-sm-12">
      <div class="pageheader">Awesome Comics Online Comics</div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 center" *ngIf="comic">
      <h3>{{comic.title}}</h3>
      <p>Creator: <span>{{comic.creator}}</span><span *ngIf="comic.creatorContact">, <a [href]="'mailto: ' + comic.creatorContact">{{comic.creatorContact}}</a></span>
      </p>
      <div class="paddingBottom10">
          <img class="img-responsive center-block" [src]="'/images/localcomics/' + comicImage.image" />
      </div>
      <div class="col-sm-12 center" *ngIf="comic.pages > 1">
        <lib-awesome-lib-pager [pageModel]="pagingModel" (pageChanged)="pageChanged($event)"></lib-awesome-lib-pager>          
      </div>
      <div class="col-sm-12 pt-2">
          <button class="btn btn-primary" routerLink="/onlinecomics" [queryParams]="{p:localPaging.p, f: localPaging.f, s: localPaging.s}">Back To Comics</button>
      </div>
  </div>
</div>