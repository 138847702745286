import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'lib-awesome-lib-text-debounce',
  template: `
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" id="addon-wrapping"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" class="form-control"  [ngModel]="textModel" [placeholder]="textPlaceholder" (ngModelChange)="textModelChanged($event)" />
        </div>
  `,
  styles: []
})
export class AwesomeLibTextDebounceComponent  {

  @Input() textPlaceholder: string;
  @Output() textChanged = new EventEmitter<string>();
  searchText: string
  textChangedSource: Subject<string> = new Subject<string>();
  @Input() textModel: string;

  constructor() { 
    this.textChangedSource.pipe(
      debounceTime(1000), 
      distinctUntilChanged()).subscribe(x => {
        this.onTextChanged(x);
      }
      );
  }

  onTextChanged(val: string) :  void {
    this.textChanged.emit(val);
  }

  textModelChanged($event: string){
    this.textChangedSource.next($event);
  }

}
