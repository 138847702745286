import { Component, Input, Output } from '@angular/core';
import { CreditCard } from 'awesome-lib';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
    styles: []
})
export class CreditCardComponent {


    @Input() creditCard: CreditCard = new CreditCard();
    @Input() isReadOnly: boolean;
    @Input() isEditNumber: boolean;

    constructor() { }


}
