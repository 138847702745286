import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './awesome/home/home.component';
import { LocalComponent } from './awesome/local/local.component';
import { LocalComicComponent } from './awesome/local-comic/local-comic.component';
import { EbayComponent } from './awesome/ebay/ebay.component';
import { MaillistComponent } from './awesome/maillist/maillist.component';
import { SubscriptionComponent } from './awesome/subscription/subscription.component';
import { SubscriptionGuard } from './shared/guards/subscription.guard';
import { StoreComponent } from './awesome/store/store.component';
import { GiftCertificateComponent } from './awesome/gift-certificate/gift-certificate.component';
import { MiscComponent } from './awesome/misc/misc.component';
import { EventsComponent } from './awesome/events/events.component';
import {ImageDialogComponent} from './awesome/dialog/image-dialog.component';
import {AbramsComponent} from './awesome/store/abrams/abrams.component';
import {RegalRowComponent} from './awesome/store/regal-row/regal-row.component';

const routes: Routes = [

  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'onlinecomics',
    component: LocalComponent
  },
  {
    path: 'onlinecomic/:id',
    component: LocalComicComponent
  },
  {
    path: 'ebay',
    component: EbayComponent
  },
  {
    path: 'maillist',
    component: MaillistComponent
  },
  {
    path: 'subscriptionInfo',
    component: SubscriptionComponent,
    canActivate: [SubscriptionGuard]
  },
  {
    path: 'store',
    component: StoreComponent
  },
  {
    path: 'giftcertificates',
    component: GiftCertificateComponent
  },
  {
    path: 'misc', 
    component: MiscComponent
  },
  {
    path: 'events', 
    component: EventsComponent
  },
  {
    path: 'store/abrams',
    component: AbramsComponent
  },
  {
    path: 'store/regal-row',
    component: RegalRowComponent
  },
  {
    path: 'account',
    loadChildren: () => import('../app/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'happymedium', 
    loadChildren: () => import('./happymedium/happymedium.module').then(m => m.HappymediumModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }, 
  {
    path: '**', 
    component: HomeComponent
  }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};


@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


export const components = [HomeComponent, EbayComponent, LocalComponent, MaillistComponent, AbramsComponent, RegalRowComponent,
  StoreComponent, SubscriptionComponent, LocalComicComponent, GiftCertificateComponent, MiscComponent, EventsComponent, ImageDialogComponent];
