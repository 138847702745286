import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AwesomeToken } from 'awesome-lib';
import { AwesomeTokenHandlerService } from 'awesome-lib';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(private tokenService: AwesomeTokenHandlerService, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {


    let token: AwesomeToken = this.tokenService.getValidToken();

    if (!token) {
      return true;
    }

    this.router.navigate(['/subscription/dashboard']);;
  }
}
