<div *ngIf="creditCard">
    <form name="ccform" #ccform="ngForm" *ngIf="!isReadOnly">
        <div class="row">
            <div class="col-12 col-sm-6">
                <label for="ccname">Name</label>
                <input type="text" name="ccname" class="form-control" [(ngModel)]="creditCard.name" required #ccname="ngModel" />
            </div>
            <div class="col-12 col-sm-6">
                <label for="cc-number">Credit Card Number</label>
                <input type="tel" autocomplete="cc-number" id="cc-number" ccNumber name="cc-number" class="form-control" *ngIf="isEditNumber" required
                    [(ngModel)]="creditCard.creditCardNumber">
                <div *ngIf="!isEditNumber">ends in <b><i>{{creditCard.creditCardNumber}}</i></b></div>
            </div>
            <div class="col-12 col-sm-6">
                <label for="cc-exp-date">Credit Card Expiration</label>
                <input id="cc-exp-date" type="tel" autocomplete="cc-exp" ccExp name="cc-exp-date" class="form-control" required
                    [(ngModel)]="creditCard.creditCardExpiration">
            </div>
            <div class="col-12 col-sm-6">
                <label for="cc-cvc">Security Code</label>
                <input id="cc-cvc" type="tel" name="cc-cvc" autocomplete="off" ccCVC class="form-control" required
                    [(ngModel)]="creditCard.creditCardCVC" />
            </div>
            <div class="col-12 col-sm-6">
                <label for="billingzip">Billing Zip Code</label>
                <input type="text" class="form-control" [(ngModel)]="creditCard.billingZipCode" required
                    name="billingZip" #billingzip="ngModel" />
            </div>
        </div>
    </form>
    <div *ngIf="isReadOnly">
        <div style="text-indent: 15px;">
            <div class="row">
                <div class="col">Name on card: <b><i>{{creditCard.name}}</i></b></div>
            </div>
            <div class="row">
                <div class="col">Card Number: ends in <b><i>{{creditCard.creditCardNumber}}</i></b></div>
            </div>
            <div class="row">
                <div class="col">Card Expiration: <b><i>{{creditCard.creditCardExpiration}}</i></b></div>
            </div>
            <div class="row">
                <div class="col">CVC/Security Code: <b><i>{{creditCard.creditCardCVC}}</i></b></div>
            </div>
            <div class="row">
                <div class="col">Billing Zip Code: <b><i>{{creditCard.billingZipCode}}</i></b></div>
            </div>
        </div>
    </div>
</div>