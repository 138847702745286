import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AwesomeTokenHandlerService } from 'awesome-lib';
import { AwesomeToken } from 'awesome-lib';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private tokenService: AwesomeTokenHandlerService, private router: Router) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      
        if(this.hasToken()) return true;

        this.router.navigate(['/account/login']);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if(this.hasToken()) return true;

        this.router.navigate(['/account/login']);
    }

    private hasToken(): boolean {
        let token: AwesomeToken = this.tokenService.getValidToken();

        if (token) {
            return true;
        }

        return false;
    }
}
