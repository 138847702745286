<div class="row">
        <div class="col-sm-12">
                <div class="pageheader">HeadQuarters ~ Regal Row Location</div>
        </div>
</div>
<div class="row pt-2">
        <div class="featuredtext col">
                <p class="bigandboldblack font20">Awesome Comics has finally expanded our empire! Check out our new
                        location -
                        the Secret World Headquarters</p>
        </div>
</div>
<div class="row">
        <div class="col-sm-6">
                The Regal Building
                <br />
                1720 Regal Row, Dallas, Tx. 75235
                <br />
                (Right off I-35 & Regal Row - next to the 24-Hour Poker House & Whataburger!)
                <br />
                682-395-7429
                <br />
                <div class="padding10">
                        HOURS:
                        <ul>
            <li>Thursday 12-7</li>
            <li>Friday 12-7</li>
            <li>Saturday 12-6</li>
            <li>Sunday 12-6</li>
        </ul>
        </div>
                <p>The new location has a large selection of back issues, comic sets, graphic novels & toys too! Mostly
                        comics
                        from collections that we bought over the past 4 years that we didn't have room for at our main
                        location.
                        We'll of course be doing nice extra discounts on our already low prices!</p>
                <p>Please take caution while parking in the front area spaces. There's a lot of parking spaces in front,
                        but there's also a decent amount of cars coming and going from some of the other businesses in
                        the
                        building. The parking spaces on the right side of the building are pretty open all the time, so
                        that's a
                        good area to consider parking.</p>
                <p>Thank you all!</p>
                <p>Jake & Co.</p>
        </div>
        <div class="col-sm-6">
                <p class="d-xs-none"><iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26819.234711229517!2d-96.91480494205446!3d32.834544354562205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9c50cff26ffd%3A0x396a294392230c55!2s1720%20Regal%20Row%2C%20Dallas%2C%20TX%2075235!5e0!3m2!1sen!2sus!4v1669431009903!5m2!1sen!2sus"
                                width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe></p>
                <p class="d-none d-xs-block"><iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26819.234711229517!2d-96.91480494205446!3d32.834544354562205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9c50cff26ffd%3A0x396a294392230c55!2s1720%20Regal%20Row%2C%20Dallas%2C%20TX%2075235!5e0!3m2!1sen!2sus!4v1669431009903!5m2!1sen!2sus"
                                width="300" height="200" style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe></p>
        </div>
</div>
        <a name="photos"></a>
        <p></p>
<div class="row">
    <div class="col-sm-12 text-center">
        <p>Take a tour of the new location</p>
        <object width="700px" 
        height="600px"  data="https://www.awesomecomicsonline.com/regal_row.mp4" type="video/mp4">
        </object>
    </div>
</div>
<div class="row pb-1 mt-3">
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_30.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_29.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_28.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_27.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_26.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_25.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_24.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_23.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_22.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_21.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_20.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_19.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_18.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_17.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_16.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_15.png"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_14.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_13.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_12.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_11.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_10.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_09.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_08.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_06.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_02.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_07.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_03.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_04.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_05.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
        <div class="col text-center pb-1"><img src="/images/store/regal-row/ns_01.jpg"
                        alt="Awesome Comics, Dallas' Premiere Comic Shop with our new Secret Headquarters, 10% off walk-in special on everything in the store, excluding sale items. Subscription subscribers get 20% off all new comics, and 15% off everything else in the store not already on sale." />
        </div>
</div>