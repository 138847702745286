import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private showLoader = new Subject<boolean>();
  loadCountAction$ = this.showLoader.asObservable();

  constructor() { }


  doLoadCountAction(loadCount: number) : void {
    if(loadCount > 0) {
      this.showLoader.next(true);
    }
    else {
      this.showLoader.next(false);      
    }
  }

  clearLoader() : void {
    this.showLoader.next(false);
  }
}
