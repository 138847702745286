<div class="site-container">
    <div class="container">
        <div class="row d-none d-md-flex">
            <div class="col-md-8 col-sm-12">
                <a href routerLink="/"><img src="/images/header/new-header.png" border="0" /></a>
            </div>
            <div class="col-md-4 sideHeader d-md-none d-lg-block">

                <div class="row">
                    <div class="col-sm-12">
                        <span class="pull-right paddingRight10"><a href="http://www.facebook.com/awesomecomics"
                                target="_blank"><img src="/images/fpg/facebook_icon.jpg"
                                    alt="Awesome Comics is now of Facebook. Friend us and stay abreast of all the latest happenings, up-to-the-minute!"
                                    border="0" /></a></span>
                        <span class="pull-right frontpageEnvelope"><a class="blacklinklarger"
                                href="mailto:awesome_comics@yahoo.com"><i class="fa fa-envelope-o fa-2x"></i></a></span>
                    </div>
                </div>
                <span class="premieretext pull-right">Dallas' Premiere Comic Store</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-lg navbar-dark">
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarAwesomeContent" aria-controls="navbarAwesomeContent"
                        aria-expanded="false" aria-label="Toggle navigation" #awesomeNav>
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand d-block d-md-none" href="/"><img
                            src="/images/header/new-header-xtra-small-text.png" border="0" /></a>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="navbarAwesomeContent">
                        <ul class="nav navbar-nav mr-auto">
                            <li class="nav-item"><a (click)="collapseNav()"  class="nav-link" href routerLink="/subscriptionInfo">Subscription
                                    Service</a></li>
                            <li class="nav-item"><a (click)="collapseNav()" class="nav-link" href routerLink="/maillist">Mailing List</a></li>
                            <li class="nav-item"><a (click)="collapseNav()" class="nav-link" href routerLink="/store">Store</a></li>
                            <li class="nav-item"><a (click)="collapseNav()" class="nav-link" href routerLink="/ebay">Ebay</a></li>
                            <li class="nav-item"><a href (click)="collapseNav()" class="nav-link" routerLink="/onlinecomics">Online
                                    Comics</a></li>
                            <li class="nav-item"><a href (click)="collapseNav()" class="nav-link" routerLink="/giftcertificates">Gift
                                    Certificates</a></li>
                            <li class="nav-item dropdown d-none d-md-block">
                                <a href class="nav-link dropdown-toggle" id="navbarDropdown" data-toggle="dropdown"
                                    role="button" aria-haspopup="true" aria-expanded="false">Additional
                                    Links</a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href routerLink="/misc" fragment="toys">Toys</a>
                                    <a class="dropdown-item" href routerLink="/misc"
                                        fragment="silver">Silver/Bronze/Modern Age Comics</a>
                                    <a class="dropdown-item" href routerLink="/misc" fragment="discount">Discounted
                                        Comics</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href routerLink="./happymedium">Happy Medium</a>
                                    <a class="dropdown-item" href routerLink="./happymedium/aldente">Al Dente</a>
                                    <a class="dropdown-item" href routerLink="./happymedium/footnotes">The Footnotes</a>
                                    <a class="dropdown-item" href routerLink="./happymedium/riffschlitz">Riff Schlitz</a>
                                </div>
                            </li>
                        </ul>
                        <ul class="nav navbar-nav mr-2" *ngIf="isAuthenticated">
                            <li class="nav-item">
                                <a (click)="collapseNav()" class="nav-link" href routerLink="/account/profile">Hello {{name}}</a>
                            </li>
                            <li class="nav-item">
                                <div (click)="collapseNav()" class="nav-link-div cursor" (click)="logout()">Log off</div>
                            </li>
                        </ul>
                        <ul class="nav navbar-nav mr-2" *ngIf="!isAuthenticated">
                            <li class="nav-item"><a (click)="collapseNav()" class="nav-link" href routerLink="./account/register">Register</a>
                            </li>
                            <li class="nav-item"><a (click)="collapseNav()" class="nav-link" href routerLink="./account/login">Log In</a></li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                </nav>

            </div>
        </div>
    </div>
    <div class="container body-content mb-5">
        <app-loader></app-loader>
        <router-outlet></router-outlet>
    </div>
    <div class="container-fluid bckgrnd d-flex flex-column flex-grow">
        <div class="container">
            <footer>
                <div class="row">
                    <div class="col bckgrnd p-3">
                        <div class="footer-text">
                            Awesome Comics, Awesomus, Awesomus logo, and all Awesomus characters are
                            &copy;{{year}} by Awesome Comics. Contact: 214-341-7033 or <a class="bckgrnd-link underline"
                                href="mailto:awesome_comics@yahoo.com">Awesome_Comics@yahoo.com</a>.
                            For more store information, including directions and exclusive photos, <a
                                class="bckgrnd-link underline" href routerLink="/awesome/store">visit our
                                Store</a>. <a class="bckgrnd-link underline" href="mailto:awesome_comics@yahoo.com"><i
                                    class="fa fa-envelope-o fa"></i> Email us</a>
                            with any comments, question or issues.
                        </div>
                        <div class="row p-3">
                            <div class="col-sm-4 col-12">
                                <p><a class="bckgrnd-link" href routerLink="/subscriptionInfo">Subscription Service</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/maillist">Mailing List</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/store">Store</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/ebay">Ebay</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/giftcertificates">Gift Certificates</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/events">Events</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/onlinecomics">Online Comics</a></p>                                
                            </div>
                            <div class="col-sm-4 col-12">
                                <p><a class="bckgrnd-link" href routerLink="/misc" fragment="discount">Discounted Comics</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/misc" fragment="silver">Silver/Bronze/Modern Age Comics</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/misc" fragment="toys">Toys</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/happymedium">Happy Medium</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/happymedium/aldente">Al Dente</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/happymedium/footnotes">The Footnotes</a></p>
                                <p><a class="bckgrnd-link" href routerLink="/happymedium/riffschlitz">Riff Schlitz</a>
                                </p>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div *ngIf="isAuthenticated; else elseBlock">
                                    <p><a class="bckgrnd-link" href routerLink="./account/profile">Edit Profile</a></p>        
                                    <p><a class="bckgrnd-link" href routerLink="./account/changepassword">Change Your Password</a></p>   
                                    <p><a class="bckgrnd-link" href routerLink="./subscription/sublist">Edit Your Subscription List</a></p>  
                                    <p><a class="bckgrnd-link" href routerLink="./subscription/payment">Edit Payment Info</a></p>     
                                </div>
                                <ng-template #elseBlock>
                                    <p><a class="bckgrnd-link" href routerLink="./account/register">Register</a></p>
                                    <p><a class="bckgrnd-link" href routerLink="./account/login">Login</a></p>
                                    <p><a class="bckgrnd-link" href routerLink="./account/forgotpassword">Forgot Password</a></p>
                                    <p><a class="bckgrnd-link" href routerLink="./account/resendvalidation">Resend Validation Link</a></p>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</div>

