import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AwesomeLibTextDebounceComponent } from './awesome-lib-text-debounce.component';
import { AwesomeLibPagerComponent } from './awesome-lib-pager.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  declarations: [AwesomeLibTextDebounceComponent, AwesomeLibPagerComponent],
  imports: [
    FormsModule,     
    PaginationModule.forRoot(), 
  ],
  exports: [AwesomeLibTextDebounceComponent, AwesomeLibPagerComponent]
})
export class AwesomeLibModule { }
