import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AwesomeQueryParams } from 'src/app/awesome/shared/awesome-models';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class CoreService {

    constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

    postQueryParams(params: AwesomeQueryParams) {
        return this.httpClient.post('/api/awesome/params/query', params, ).pipe(catchError(this.errorHandlerService.handleError));
    }
}
