<div class="row">
    <div class="col"><div class="pageheader">Awesome Comics Customer Center</div></div>
</div>
<div class="row">
    <div class="col">
        <p>
            <b>
                <a [routerLink]="['/account/register']">Sign Me Up</a>  |
                <a [routerLink]="['/account/login']">Log In</a>
            </b>
        </p>
        <p><span class="bigandboldblack">Subscription Service</span></p>
        <p>
            Tired of the guy in front of you nabbing the last issue of that red-hot comic book
            you’ve been dying for, well put your worries to rest with the greatest invention since the
            George Foreman Grill—the Awesome Comics Subscription Service!
        </p>
        <p>
            Every Wednesday morning we wake up at the butt crack of dawn to get all the new comics ready
            for consumer consumption.  Subscriptions are usually filled by about noon to one o’clock.
        </p>
        <p>
            By signing up for the Subscription Service you will receive the following
            cash and prizes: </p>
            <ul>
                <li>Dramatic stress relief.</li>
                <li>More friends instantly.  Wow, it&#146;s magic!</li>
                <li>20% all new comics (subscription and non) and 15% off everything else in the store not already on sale.</li>
                <li>Never miss an issue of your favorite comic ever again.</li>
            </ul>
        <p>All conditions apply to in-store and mail order accounts.</p>
        <p></p>
    </div>
</div>