import { Component, ViewChild } from '@angular/core';
import { Maillist } from '../shared/awesome-models';
import { AwesomeService } from '../shared/awesome.service';
import { MessagingLibraryService } from 'angular-messaging-library';

@Component({
    selector: 'app-maillist',
    templateUrl: './maillist.component.html',
    styles: []
})
export class MaillistComponent {

    @ViewChild('mailForm', { static: true }) frmValues;
    maillist: Maillist = new Maillist();
    isBusy: boolean = false;

    constructor(private awesomeService: AwesomeService, private messageService: MessagingLibraryService) {
    }

    submit() {
        this.isBusy = true;
        this.awesomeService.sendMailList(this.maillist).subscribe(
            res => {
                //todo: send to message service                
                this.isBusy = false;
                this.maillist = new Maillist();
                this.frmValues.resetForm();
                this.messageService.addInfoMessage(res);
            },
            err => {
                this.isBusy = false;
                this.messageService.addInfoMessage(err);
            }
        )
    }

}
